import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/@next+third-parties@14.2.11_next@14.2.11_@babel+core@7.24.6_@opentelemetry+api@1.8.0_react-do_mcvm6twfhxfhayv2xpsamzljq4/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/@next+third-parties@14.2.11_next@14.2.11_@babel+core@7.24.6_@opentelemetry+api@1.8.0_react-do_mcvm6twfhxfhayv2xpsamzljq4/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/@next+third-parties@14.2.11_next@14.2.11_@babel+core@7.24.6_@opentelemetry+api@1.8.0_react-do_mcvm6twfhxfhayv2xpsamzljq4/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.6_@opentelemetry+api@1.8.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.6_@opentelemetry+api@1.8.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/core/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../app/static/fonts/charlevoix-pro/PPCharlevoix-Regular.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"../app/static/fonts/charlevoix-pro/PPCharlevoix-Medium.woff2\",\"style\":\"normal\",\"weight\":\"500\"},{\"path\":\"../app/static/fonts/charlevoix-pro/PPCharlevoix-Semibold.woff2\",\"style\":\"normal\",\"weight\":\"600\"},{\"path\":\"../app/static/fonts/charlevoix-pro/PPCharlevoix-Bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"}],\"adjustFontFallback\":false,\"display\":\"swap\",\"fallback\":[\"system-ui\",\"arial\"],\"preload\":true,\"variable\":\"--font-pp-charlevoix\"}],\"variableName\":\"charlevoixProFont\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.6_@opentelemetry+api@1.8.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/core/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../app/static/fonts/maison-neue/MaisonNeue-Light.woff2\",\"style\":\"normal\",\"weight\":\"300\"},{\"path\":\"../app/static/fonts/maison-neue/MaisonNeue-Book.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"../app/static/fonts/maison-neue/MaisonNeue-Bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"}],\"adjustFontFallback\":false,\"display\":\"swap\",\"fallback\":[\"system-ui\",\"arial\"],\"preload\":false,\"variable\":\"--font-maison-neue\"}],\"variableName\":\"maisonNeueFont\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.6_@opentelemetry+api@1.8.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/core/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../app/static/fonts/tiempos/TiemposHeadlineWeb-Light.woff2\",\"style\":\"normal\",\"weight\":\"300\"},{\"path\":\"../app/static/fonts/tiempos/TiemposTextWeb-Regular.woff2\",\"style\":\"normal\",\"weight\":\"400\"}],\"adjustFontFallback\":false,\"display\":\"swap\",\"fallback\":[\"system-ui\",\"arial\"],\"preload\":false,\"variable\":\"--font-tiempos-headline\"}],\"variableName\":\"tiemposHeadlineFont\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.11_@babel+core@7.24.6_@opentelemetry+api@1.8.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/core/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../app/static/fonts/proxima-nova/ProximaNova-Light.woff\",\"style\":\"normal\",\"weight\":\"300\"},{\"path\":\"../app/static/fonts/proxima-nova/ProximaNova.woff\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"../app/static/fonts/proxima-nova/ProximaNova-Bold.woff\",\"style\":\"normal\",\"weight\":\"700\"}],\"adjustFontFallback\":false,\"display\":\"swap\",\"fallback\":[\"system-ui\",\"arial\"],\"preload\":true,\"variable\":\"--font-proxima-nova\"}],\"variableName\":\"proximaNovaFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/opt/build/repo/src/providers/index.tsx");
