import { datadog, env } from '@/utility/environment'

/**
 * Initialize the Datadog RUM agent.
 * @see https://docs.datadoghq.com/real_user_monitoring/browser/
 * @see https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters
 */
async function init(applicationId: string, clientToken: string) {
  const { datadogRum } = await import('@datadog/browser-rum')

  datadogRum.init({
    allowedTracingUrls: [
      (url) =>
        url.includes('aptouring.com') || url.includes('travelmarvel.com'),
    ],
    applicationId,
    clientToken,
    defaultPrivacyLevel:
      env.name === 'uat' || env.name === 'prod' ? 'mask-user-input' : 'allow',
    env: env.name,
    service: 'website',
    sessionReplaySampleRate: Number(datadog.replaySampleRate),
    sessionSampleRate: Number(datadog.sampleRate),
    site: datadog.site,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    trackViewsManually: true,
    version: env.version,
  })

  // no-dd-sa
  window.addEventListener('datadog.trackview', ({ detail }) => {
    datadogRum.startView({ name: detail.viewName })
  })
}

if (
  env.name !== 'deploy-preview' &&
  datadog.appId &&
  datadog.appId.length > 34 &&
  datadog.clientToken &&
  datadog.clientToken.length > 32 &&
  typeof window !== 'undefined'
) {
  init(datadog.appId, datadog.clientToken)
}
