'use client'

import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'

import type { ContentfulLivePreviewInitConfig } from '@contentful/live-preview'

export function ContentfulPreviewProvider({
  children,
  ...props
}: Readonly<React.PropsWithChildren<ContentfulLivePreviewInitConfig>>) {
  return (
    <ContentfulLivePreviewProvider {...props}>
      {children}
    </ContentfulLivePreviewProvider>
  )
}
