const publicKey = process.env.REQUEST_PUBLIC_KEY

export const datadog = {
  /**
   * The RUM application ID.
   * @default undefined
   */
  appId: process.env.DATADOG_RUM_APP_ID,

  /**
   * The client token for Datadog.
   * @default undefined
   */
  clientToken: process.env.DATADOG_CLIENT_TOKEN,

  /**
   * The percentage of tracked sessions with [Browser RUM & Session Replay pricing](https://www.datadoghq.com/pricing/?product=real-user-monitoring--session-replay#real-user-monitoring--session-replay) features: 100 for all, 0 for none.
   */
  replaySampleRate: Number(process.env.DATADOG_REPLAY_SAMPLE_RATE),

  /**
   * The percentage of sessions tracked. A value between 0 and 100.
   * @default 100
   */
  sampleRate: Number(process.env.DATADOG_SAMPLE_RATE),

  /**
   * The Datadog [site](https://docs.datadoghq.com/getting_started/site).
   * @default us3.datadoghq.com
   */
  site: process.env.DATADOG_SITE,
} as const

export const env = {
  /**
   * The code of the brand that the application is configured for.
   * @default undefined
   */
  brand: process.env.NEXT_PUBLIC_WEB_BRAND,

  /**
   * The locale that should be used as the default locale.
   */
  fallbackLocale: 'en-AU',

  /**
   * The name of the environment the application is running in.
   */
  name: process.env.WEB_ENVIRONMENT,

  /**
   * The version of the application.
   */
  nextVersion: process.env.NEXT_VERSION,

  /**
   * The version of the application.
   */
  version: process.env.APP_VERSION,
} as const

export const security = {
  /**
   * The nonce value used for Content Security Policy.
   *
   * It can be generated using the `crypto` module using the `node` REPL. Store the value in an
   * environment variable called `NEXT_PUBLIC_NONCE`.
   * @example
   * ```ts
   * Buffer.from(crypto.randomUUID()).toString('base64')
   * ```
   */
  nonce: process.env.NEXT_PUBLIC_NONCE,

  /**
   * The public key used to encrypt request data bfore sending it to the server.
   * When the public key is not provided, the request data is sent as plain text.
   *
   * @default null
   */
  publicKey: (publicKey && publicKey.length > 0
    ? JSON.parse(atob(publicKey))
    : null) as JsonWebKey | null,
} as const

export const algolia = {
  /**
   * The Algolia application ID.
   */
  applicationId: process.env.ALGOLIA_APPLICATION_ID,

  /**
   * The Algolia search-only API key.
   */
  searchApiKey: process.env.ALGOLIA_SEARCH_API_KEY,

  /**
   * The Algolia search index name.
   */
  searchIndex: {
    tripSearch: process.env.ALGOLIA_TRIP_SEARCH_INDEX,
  },
} as const

/**
 * Validate the environment name to ensure it is one of the allowed values.
 *
 * @param env The environment name to validate.
 * @returns `true` the environment name is valid, `false` otherwise.
 */
export function isValidEnvironment(env: NodeJS.ProcessEnv['WEB_ENVIRONMENT']) {
  return /local|deploy-preview|qa|uat|prod/.test(env)
}
