import { useContext as useReactContext } from 'react'

type Context<T> = T extends React.Context<infer R> ? Exclude<R, null> : unknown

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useContext<T extends React.Context<any>>(
  Context: T,
): Context<T> {
  const context = useReactContext(Context)

  if (!context) {
    throw new Error(
      `Missing ${Context.displayName}.Provider in the component tree!`,
    )
  }

  return context
}
