'use client'

import { usePathname } from 'next/navigation'
import { NextIntlClientProvider, type AbstractIntlMessages } from 'next-intl'
import { useEffect } from 'react'

import { ContentfulPreviewProvider } from '@/providers/live-preview'
import { SiteProvider } from '@/providers/site'

import { env } from '@/utility/environment'
import type { PageSlug } from '@/types/resolved-types'

import '@/core/rum'

type BaseProviderProps = {
  locale: string
  messages: AbstractIntlMessages
  slugs?: PageSlug[]
  siteConfig: ATGProvider.SiteContextProps
}

export function BaseProviders({
  children,
  locale,
  messages,
  slugs,
  siteConfig,
}: Readonly<React.PropsWithChildren<BaseProviderProps>>) {
  return (
    <SiteProvider {...siteConfig} slugs={slugs}>
      <NextIntlClientProvider locale={locale} messages={messages}>
        {children}
      </NextIntlClientProvider>
    </SiteProvider>
  )
}

export function Providers({
  brand,
  children,
  contentfulEnv,
  contentfulSpace,
  isContentfulPreview = false,
  isDraftMode = false,
  locale,
  messages,
  originalLocale,
  slugs,
}: Readonly<
  React.PropsWithChildren<
    ATGProvider.SiteContextProps & {
      messages: AbstractIntlMessages
      allSlugs?: PageSlug[]
    }
  >
>) {
  const pathname = usePathname()

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent<WindowEventMap['datadog.trackview']['detail']>(
        'datadog.trackview',
        {
          detail: {
            viewName: pathname,
          },
        },
      ),
    )
  }, [pathname])

  const baseConfig: BaseProviderProps = {
    locale,
    messages,
    slugs,
    siteConfig: {
      brand,
      contentfulEnv,
      contentfulSpace,
      isContentfulPreview,
      isDraftMode,
      locale,
      originalLocale,
    },
  }

  if (isContentfulPreview) {
    return (
      <BaseProviders {...baseConfig}>
        <ContentfulPreviewProvider
          debugMode={env.name === 'local' || env.name === 'qa'}
          enableInspectorMode
          enableLiveUpdates
          locale={originalLocale}
        >
          {children}
        </ContentfulPreviewProvider>
      </BaseProviders>
    )
  }

  return <BaseProviders {...baseConfig}>{children}</BaseProviders>
}
