'use client'

import { createContext, useCallback, useEffect, useMemo, useState } from 'react'

import { useContext } from '@/components/hooks/use-context'

type SiteProviderFields = ATGProvider.SiteContextProps & {
  isContentfulFrame: boolean

  getLocale: () => string
}

const SiteContext = createContext<SiteProviderFields>({} as never)

SiteContext.displayName = 'SiteContext'

export function useSite() {
  return useContext(SiteContext)
}

export function SiteProvider({
  children,
  ...props
}: Readonly<React.PropsWithChildren<ATGProvider.SiteContextProps>>) {
  const [isContentfulFrame, setIsContentfulFrame] = useState(false)

  const getLocale = useCallback(() => {
    return props.locale !== props.originalLocale
      ? props.locale
      : props.originalLocale
  }, [props.locale])

  useEffect(() => {
    try {
      setIsContentfulFrame(window.self !== window.top)
    } catch {}
  }, [])

  const value = useMemo(
    () => ({
      ...props,
      isContentfulFrame,
      getLocale,
    }),
    [props, isContentfulFrame],
  )

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
}
